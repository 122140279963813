import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import parse from "html-react-parser";
import { BiSearch } from "react-icons/bi";
import { FaHandsHelping, FaStar, FaWrench } from "react-icons/fa";
import { MdPerson } from "react-icons/md";
import { TiChartLine } from "react-icons/ti";

const WhyChooseRJMSection = ({
	experienced,
	bespoke,
	provenResults,
	reliable,
	transparent,
	trustworthy,
	title,
	heading1,
	heading2,
	heading3,
	heading4,
	heading5,
	heading6,
}) => {
	const titleNew =
		title ||
		`<h2 className="text-light-grey display-5">
			WHY CHOOSE <span className="text-secondary">RJM</span>?
		</h2>`;

	const newHeading1 = heading1 || "Experienced";
	const newHeading2 = heading2 || "Bespoke";
	const newHeading3 = heading3 || "Proven Results";
	const newHeading4 = heading4 || "Reliable";
	const newHeading5 = heading5 || "Transparent";
	const newHeading6 = heading6 || "Trustworthy";
	return (
		<section className="bg-dark-grey py-5 pt-lg-7 pb-lg-8">
			<Container>
				<Row className="mb-5">
					<Col className="text-center">
						<div>{parse(titleNew)}</div>
					</Col>
				</Row>

				<Row className="g-4">
					<Col xs={12} md={6} xl={4}>
						<div
							style={{ minHeight: "100%" }}
							className="bg-dark-med-grey text-med-grey rounded text-center p-5"
						>
							<div
								style={{ width: "100%", height: "90px" }}
								className="position-relative mt-2"
							>
								<p
									style={{ width: "90px", height: "90px" }}
									className="position-absolute start-50 translate-middle-x bg-dark-grey p-1 d-flex align-items-center justify-content-center rounded-circle"
								>
									<MdPerson
										className="text-secondary"
										style={{ fontSize: "50px" }}
									/>
								</p>
							</div>
							<div className="mt-4">
								<h3>{newHeading1}</h3>
							</div>
							<div className="mt-4">
								<p>{experienced}</p>
							</div>
						</div>
					</Col>
					<Col xs={12} md={6} xl={4}>
						<div
							style={{ minHeight: "100%" }}
							className="bg-dark-med-grey text-med-grey rounded text-center p-5"
						>
							<div
								style={{ width: "100%", height: "90px" }}
								className="position-relative mt-2"
							>
								<p
									style={{ width: "90px", height: "90px" }}
									className="position-absolute start-50 translate-middle-x bg-dark-grey p-1 d-flex align-items-center justify-content-center rounded-circle"
								>
									<FaWrench
										className="text-secondary"
										style={{ fontSize: "40px" }}
									/>
								</p>
							</div>
							<div className="mt-4">
								<h3>{newHeading2}</h3>
							</div>
							<div className="mt-4">
								<p>{bespoke}</p>
							</div>
						</div>
					</Col>
					<Col xs={12} md={6} xl={4}>
						<div
							style={{ minHeight: "100%" }}
							className="bg-dark-med-grey text-med-grey rounded text-center p-5"
						>
							<div
								style={{ width: "100%", height: "90px" }}
								className="position-relative mt-2"
							>
								<p
									style={{ width: "90px", height: "90px" }}
									className="position-absolute start-50 translate-middle-x bg-dark-grey p-1 d-flex align-items-center justify-content-center rounded-circle"
								>
									<TiChartLine
										className="text-secondary"
										style={{ fontSize: "50px" }}
									/>
								</p>
							</div>
							<div className="mt-4">
								<h3>{newHeading3}</h3>
							</div>
							<div className="mt-4">
								<p>{provenResults}</p>
							</div>
						</div>
					</Col>
					<Col xs={12} md={6} xl={4}>
						<div
							style={{ minHeight: "100%" }}
							className="bg-dark-med-grey text-med-grey rounded text-center p-5"
						>
							<div
								style={{ width: "100%", height: "90px" }}
								className="position-relative mt-2"
							>
								<p
									style={{ width: "90px", height: "90px" }}
									className="position-absolute start-50 translate-middle-x bg-dark-grey p-1 d-flex align-items-center justify-content-center rounded-circle"
								>
									<FaStar
										className="text-secondary"
										style={{ fontSize: "50px" }}
									/>
								</p>
							</div>
							<div className="mt-4">
								<h3>{newHeading4}</h3>
							</div>
							<div className="mt-4">
								<p>{reliable}</p>
							</div>
						</div>
					</Col>
					<Col xs={12} md={6} xl={4}>
						<div
							style={{ minHeight: "100%" }}
							className="bg-dark-med-grey text-med-grey rounded text-center p-5"
						>
							<div
								style={{ width: "100%", height: "90px" }}
								className="position-relative mt-2"
							>
								<p
									style={{ width: "90px", height: "90px" }}
									className="position-absolute start-50 translate-middle-x bg-dark-grey p-1 d-flex align-items-center justify-content-center rounded-circle"
								>
									<BiSearch
										className="text-secondary"
										style={{ fontSize: "50px" }}
									/>
								</p>
							</div>
							<div className="mt-4">
								<h3>{newHeading5}</h3>
							</div>
							<div className="mt-4">
								<p>{transparent}</p>
							</div>
						</div>
					</Col>
					<Col xs={12} md={6} xl={4}>
						<div
							style={{ minHeight: "100%" }}
							className="bg-dark-med-grey text-med-grey rounded text-center p-5"
						>
							<div
								style={{ width: "100%", height: "90px" }}
								className="position-relative mt-2"
							>
								<p
									style={{ width: "90px", height: "90px" }}
									className="position-absolute start-50 translate-middle-x bg-dark-grey p-1 d-flex align-items-center justify-content-center rounded-circle"
								>
									<FaHandsHelping
										className="text-secondary"
										style={{ fontSize: "50px" }}
									/>
								</p>
							</div>
							<div className="mt-4">
								<h3>{newHeading6}</h3>
							</div>
							<div className="mt-4">
								<p>{trustworthy}</p>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default WhyChooseRJMSection;
