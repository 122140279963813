import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import ReviewCard from "./review-card";

const ReviewSection = ({ page, heading }) => {
	const data = useStaticQuery(graphql`
		query {
			allWpTestimonial {
				nodes {
					testimonialFields {
						name
						review
						page
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const title = heading || "CUSTOMER TESTIMONIALS";
	return (
		<Container>
			<Row>
				<Col className="text-center pb-5 pb-lg-7">
					<h2 className="display-5 text-primary ">{title}</h2>
				</Col>
			</Row>
			<Row className="g-5 justify-content-center d-none d-md-flex">
				{data.allWpTestimonial.nodes
					.filter((item, index) => item.testimonialFields.page.includes(page))
					.map((review) => (
						<Col lg={4} className="">
							<ReviewCard review={review} />
						</Col>
					))}
			</Row>
			<Row className="d-md-none review-carousal">
				<Col>
					<div>
						<Carousel interval={3000}>
							{data.allWpTestimonial.nodes
								.filter((item, index) =>
									item.testimonialFields.page.includes(page)
								)
								.map((review) => (
									<Carousel.Item>
										<div className="pb-6">
											<ReviewCard review={review} />
										</div>
									</Carousel.Item>
								))}
						</Carousel>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default ReviewSection;
